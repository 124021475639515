const actions = {
    FETCH_SUBJECTS : 'FETCH_SUBJECTS',
    FETCH_SUBJECT  : 'FETCH_SUBJECT',
    RESET_SUBJECT  : 'RESET_SUBJECT',

    fetchSubjectsAction: (data) => {
        return {
            type: actions.FETCH_SUBJECTS,
            data: data
        };
    },
    resetSubjectFormAction: () => {
        return {
            type: actions.RESET_SUBJECT,
            data: null
        };
    },
    fetchSubjectAction: (data) => {
        return {
            type: actions.FETCH_SUBJECT,
            data: data
        };
    },
}

export default actions;
