const actions = {
    FETCH_TRANSACTIONS   : 'FETCH_TRANSACTIONS',

    fetchTransactionsAction: (data) => {
        return {
            type: actions.FETCH_TRANSACTIONS,
            data: data
        };
    },
}

export default actions;
