const actions = {
    FETCH_SCHEDULES : 'FETCH_SCHEDULES',
    FETCH_SCHEDULE  : 'FETCH_SCHEDULE',
    RESET_SCHEDULE  : 'RESET_SCHEDULE',

    fetchSchedulesAction: (data) => {
        return {
            type: actions.FETCH_SCHEDULES,
            data: data
        };
    },
    resetScheduleFormAction: () => {
        return {
            type: actions.RESET_SCHEDULE,
            data: null
        };
    },
    fetchScheduleAction: (data) => {
        return {
            type: actions.FETCH_SCHEDULE,
            data: data
        };
    },
}

export default actions;
