const actions = {
    FETCH_PERIODS : 'FETCH_PERIODS',
    FETCH_PERIOD  : 'FETCH_PERIOD',
    RESET_PERIOD  : 'RESET_PERIOD',

    fetchPeriodsAction: (data) => {
        return {
            type: actions.FETCH_PERIODS,
            data: data
        };
    },
    resetPeriodFormAction: () => {
        return {
            type: actions.RESET_PERIOD,
            data: null
        };
    },
    fetchPeriodAction: (data) => {
        return {
            type: actions.FETCH_PERIOD,
            data: data
        };
    },
}

export default actions;
