import actions from "./actions";

const {FETCH_STUDENT, FETCH_STUDENTS, FETCH_NEW_STUDENTS, RESET_STUDENT} = actions;
const initState = {
    students: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    newStudents: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    student: {_id: null}
};

const StudentReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_STUDENTS:
            return {
                ...state,
                students: data
            }
        case FETCH_NEW_STUDENTS:
            return {
                ...state,
                newStudents: data
            }
        case RESET_STUDENT:
            return {
                ...state,
                student: initState.student,
            };
        case FETCH_STUDENT:
            return {
                ...state,
                student: data,
            };
        default:
            return state;
    }
}

export default StudentReducer;
