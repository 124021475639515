import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Invoice = lazy(() => import('../../container/invoice'));

const InvoiceRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/invoice`} component={Invoice}/>
        </Switch>
    );
};

export default InvoiceRoutes;
