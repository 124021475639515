const actions = {
    FETCH_RESULTS : 'FETCH_RESULTS',
    FETCH_RESULTS_RESET: 'FETCH_RESULTS_RESET',

    fetchResultsAction: (data) => {
        return {
            type: actions.FETCH_RESULTS,
            data: data
        };
    },
    resetFetchResultsFromAction: (data) => {
        return {
            type: actions.FETCH_RESULTS_RESET,
            data: data
        };
    },
}

export default actions;
