import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Col, Form, Input } from 'antd';
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '../../popup/popup';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { logOut, changePassword } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { Auth } from "../../../services/authService";
import { Modal } from "../../modals/antd-modals";
import { Button } from "../../buttons/buttons";
import { Scope } from "../../../services/scopeService";
import { Constants } from "../../../config/constants";
import { Alert } from "../../../services/alertService";
import alternative from "../../../../src/static/img/demo-profile.png"
import "../../../container/userManagement/profile/css/profile.css";
import {fetchProfileUser} from "../../../redux/userManagement/users/actionCreator";

const AuthInfo = ({ rtl }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.utilities.loading);
    const user = useSelector(state => state.umUsers.user);
    const [state, setState] = useState({ visible: false, modalType: 'primary', colorModal: false });
    const [form] = Form.useForm();
    const userInfo = Auth.getUserInfo();
    const idleTimerRef = useRef(null); // Declare idleTimerRef
    const isRefreshing = useRef(false); // Declare isRefreshing
    const params = useParams();

    const [myId, setMyId] = useState("")

    useEffect(() => {
        const extractIdFromPath = () => {
            const pathParts = window.location.pathname.split('/');
            const id = pathParts[pathParts.length - 1]; // Assuming ID is the last part of the path
            setMyId(id)
            return id;
        };

        const id = extractIdFromPath();



    }, [dispatch]);

    useEffect(() => {
        let isIdleTimerStarted = false; // Flag to track if the idle timer has been started

        const handleVisibilityChange = () => {
            const hidden = document.hidden || document.visibilityState === 'hidden';
            if (hidden && isIdleTimerStarted && !isRefreshing.current) {
                console.log("gate-1");
                clearTimeout(idleTimerRef.current);
                dispatch(logOut());
            } else if (!hidden && isRefreshing.current) {
                isRefreshing.current = false; // Reset isRefreshing to false when tab becomes visible
                if (!isIdleTimerStarted) {
                    console.log("Starting idle timer...");
                    startIdleTimer();
                }
            }
        };

        const handleBeforeUnload = () => {
            isRefreshing.current = true; // Set isRefreshing to true before unloading (refreshing)
        };

        const handleLoad = () => {
            if (isRefreshing.current) {
                isRefreshing.current = false; // Reset isRefreshing to false after page load
                if (!isIdleTimerStarted) {
                    console.log("Starting idle timer...");
                    startIdleTimer();
                }
            }
        };

        // Start idle timer
        const startIdleTimer = () => {
            idleTimerRef.current = setTimeout(() => {
                clearTimeout(idleTimerRef.current);
                console.log("Logging out due to inactivity...");
                dispatch(logOut());
            }, 55 * 60 * 1000); // 1 minute timeout
            isIdleTimerStarted = true;
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('load', handleLoad);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('load', handleLoad);
            clearTimeout(idleTimerRef.current);
        };
    }, [dispatch]);




    useEffect(() => {
        const resetTimer = () => {
            clearTimeout(idleTimerRef.current);
            idleTimerRef.current = setTimeout(logout, 55 * 60 * 1000); // 55 minutes timeout
        };

        const logout = async () => {
            try {
                if (userInfo._id && !isRefreshing.current) { // Check if user is logged in and not refreshing
                    console.log("gate-2")
                    dispatch(logOut());
                }
            } catch (error) {
                Alert.error({ title: "Something went wrong" });
            }
        };

        const onActivity = () => {
            resetTimer();
        };

        resetTimer();

        document.addEventListener('mousemove', onActivity);
        document.addEventListener('keydown', onActivity);

        return () => {
            clearTimeout(idleTimerRef.current);
            document.removeEventListener('mousemove', onActivity);
            document.removeEventListener('keydown', onActivity);
        };
    }, [dispatch, userInfo._id, idleTimerRef]);


    const SignOut = e => {
        e.preventDefault();
        dispatch(logOut());
    };

    const handleSubmit = async (data) => {
        if (userInfo._id) {
            const postData = { currentPassword: data.currentPassword, password: data.password, _id: userInfo._id }
            await dispatch(changePassword(postData, handleCancel()))
        }
    };

    const showModal = async type => {
        form.resetFields();
        setState({
            visible: true,
            modalType: type,
        });
    };

    const handleCancel = () => {
        form.resetFields();
        setState({
            visible: false,
            colorModal: false,
        });
    };

    const validateMessages = {
        required: '${label} is required!',
        lenCheck: '${label} ${min} num is required!',
        types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
        },
        number: {
            length: '${label} must be ${min} number',
        },
    };

    useEffect(() => {
        const fetchData = async () => {
           if (params.id) await dispatch(fetchProfileUser(params.id));
        };
        fetchData().then(r => {});
    }, [dispatch]);

    const userPhotoUrl = userInfo?._id === myId && user && user?.photo ? user.photo : userInfo?.photo ? `${userInfo?.photo}`
        : userInfo?.gender === "male" ?
            "https://sing-hurdco.s3.ap-southeast-1.amazonaws.com/academic/selected-photos/male-porfile.png"
            : userInfo?.gender === "female" ?
                "https://sing-hurdco.s3.ap-southeast-1.amazonaws.com/academic/selected-photos/female-profile.png"
                : `${Constants.S3_BASE_URL}user/photo/${userInfo?._id}.jpg`;

    const userContent = (
        <UserDropDwon>
            <div className="user-dropdwon">
                <figure className="user-dropdwon__info">
                    <figcaption>
                        <Heading as="h5">{userInfo.full_name}</Heading>
                        <p style={{ fontWeight: "bold" }}>{userInfo?.firstName || ""} {userInfo?.lastName || ""}</p>
                        <p>{userInfo?.email}</p>
                    </figcaption>
                </figure>
                <ul className="user-dropdwon__links">
                    <li>
                        <Link to={`/admin/user-management/profile/${userInfo?._id}`} onClick={() => console.log("Hello Dear")}>
                            <FeatherIcon icon="user" /> Profile
                        </Link>
                    </li>
                    <li>
                        <Link to={`/admin/user-management/password`} >
                            <FeatherIcon icon="lock" /> Change Password
                        </Link>
                    </li>
                </ul>
                <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                    <FeatherIcon icon="log-out" /> Sign Out
                </Link>
            </div>
        </UserDropDwon>
    );

    return (
        <InfoWraper>
            <Col md={12}>
                <Modal
                    type={state.modalType}
                    title="Password Form"
                    visible={state.visible}
                    onCancel={() => handleCancel()}
                    footer={[
                        <Button
                            form="passForm"
                            key="submit"
                            htmlType="submit"
                            type="primary"
                            disabled={isLoading}
                            size="medium">
                            {isLoading ? 'Loading...' : 'Submit'}
                        </Button>
                    ]}
                >
                    <Form
                        name="Change Password"
                        form={form}
                        id="passForm"
                        validateMessages={validateMessages}
                        onFinish={handleSubmit}>
                        <Form.Item
                            name="currentPassword"
                            rules={[{ required: true }]}
                            label="Current Password">
                            <Input.Password placeholder="Current Password" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Please enter your new password!' },
                                { min: 6, message: 'Password must be at least 6 characters long!' }
                            ]}
                            label="New Password">
                            <Input.Password placeholder="New Password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
            <div className="nav-author">
                {Scope.checkScopes(['quick_search_index']) && (
                    <Link to={`/admin/quick-search`}>
                        <Button size="small" type="primary" onClick={() => { }}>
                            <FeatherIcon icon="search" size={16} />
                            <small>Quick Search</small>
                        </Button>
                    </Link>
                )}
                <Popover placement="bottomRight" content={userContent} action="click">
                    <Link to="#" className="head-example">
                        <Avatar src={userPhotoUrl} size={40} />
                    </Link>
                </Popover>
            </div>
        </InfoWraper>
    );
};

export default AuthInfo;







// import React, {useEffect, useRef, useState} from 'react';
// import {Avatar, Col, Form, Input} from 'antd';
// import { Link } from 'react-router-dom';
// import {useDispatch, useSelector} from 'react-redux';
// import FeatherIcon from 'feather-icons-react';
// import { InfoWraper, UserDropDwon } from './auth-info-style';
// import { Popover } from '../../popup/popup';
// import { logOut, changePassword } from '../../../redux/authentication/actionCreator';
// import Heading from '../../heading/heading';
// import {Auth} from "../../../services/authService";
// import {Modal} from "../../modals/antd-modals";
// import {Button} from "../../buttons/buttons";
// import {Scope} from "../../../services/scopeService";
// import {Constants} from "../../../config/constants";
// import {Alert} from "../../../services/alertService";
// import alternative from "../../../../src/static/img/demo-profile.png"
// import "../../../container/userManagement/profile/css/profile.css";
//
// const AuthInfo = ({ rtl }) => {
//     const dispatch = useDispatch();
//     const isLoading = useSelector(state => state.utilities.loading);
//     const [state, setState] = useState({ visible: false, modalType: 'primary', colorModal: false });
//     const [form] = Form.useForm();
//     const userInfo = Auth.getUserInfo();
//
//
//     const idleTimerRef = useRef(null);
//
//     useEffect(() => {
//         if (userInfo._id) { // Check if user._id exists
//             const resetTimer = () => {
//                 clearTimeout(idleTimerRef.current);
//                 idleTimerRef.current = setTimeout(logout, 55 * 60 * 1000); // 1 minute timeout
//             };
//
//             const logout = async () => {
//                 try {
//                     dispatch(logOut());
//                 } catch (error) {
//                     Alert.error({title: "Something went wrong"})
//                 }
//             };
//
//             const onActivity = () => {
//                 resetTimer();
//             };
//
//             resetTimer();
//
//             document.addEventListener('mousemove', onActivity);
//             document.addEventListener('keydown', onActivity);
//
//             return () => {
//                 clearTimeout(idleTimerRef.current);
//                 document.removeEventListener('mousemove', onActivity);
//                 document.removeEventListener('keydown', onActivity);
//             };
//         }
//     }, [logOut, userInfo._id]);
//
//     const layout = {
//         labelCol: { span: 8 },
//         wrapperCol: { span: 16 },
//     };
//
//
//     const SignOut = e => {
//         e.preventDefault();
//         dispatch(logOut());
//     };
//
//     const handleSubmit = async (data) => {
//         if (userInfo._id) {
//             const postData = {currentPassword: data.currentPassword, password: data.password, _id: userInfo._id}
//             await dispatch(changePassword(postData, handleCancel()))
//         }
//     };
//
//     const showModal = async type => {
//         form.resetFields();
//         setState({
//             visible: true,
//             modalType: type,
//         });
//     };
//
//     const handleCancel = () => {
//         form.resetFields();
//         setState({
//             visible: false,
//             colorModal: false,
//         });
//     };
//
//     const validateMessages = {
//         required: '${label} is required!',
//         lenCheck: '${label} ${min} num is required!',
//         types: {
//             email: '${label} is not validate email!',
//             number: '${label} is not a validate number!',
//         },
//         number: {
//             length: '${label} must be ${min} number',
//         },
//     };
//
//     const userPhotoUrl = userInfo?.photo ? `${userInfo?.photo}`
//         : userInfo?.gender === "male" ?
//             "https://sing-hurdco.s3.ap-southeast-1.amazonaws.com/academic/selected-photos/male-porfile.png"
//             : userInfo?.gender === "female" ?
//                 "https://sing-hurdco.s3.ap-southeast-1.amazonaws.com/academic/selected-photos/female-profile.png"
//                 : `${Constants.S3_BASE_URL}user/photo/${userInfo?._id}.jpg`;
//
//
//     const userContent = (
//         <UserDropDwon>
//             <div className="user-dropdwon">
//                 <figure className="user-dropdwon__info">
//                     <figcaption>
//                         <Heading as="h5">{userInfo.full_name}</Heading>
//                         <p style={{fontWeight: "bold"}}>{userInfo?.firstName || ""} {userInfo?.lastName || ""}</p>
//                         <p>{userInfo?.email}</p>
//                     </figcaption>
//                 </figure>
//                 <ul className="user-dropdwon__links">
//                     <li>
//                         <Link to={`/admin/user-management/profile/${userInfo?._id}`} onClick={() => console.log("Hello Dear")}>
//                             <FeatherIcon icon="user" /> Profile
//                         </Link>
//                     </li>
//                     <li>
//                         <Link to="#" onClick={() => showModal('primary')}>
//                             <FeatherIcon icon="lock" /> Change Password
//                         </Link>
//                     </li>
//                 </ul>
//                 <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
//                     <FeatherIcon icon="log-out" /> Sign Out
//                 </Link>
//             </div>
//         </UserDropDwon>
//     );
//
//     return (
//         <InfoWraper>
//             <Col md={12}>
//                 <Modal
//                     type={state.modalType}
//                     title="Password Form"
//                     visible={state.visible}
//                     onCancel={() => handleCancel()}
//                     footer={[
//                         <Button
//                             form="passForm"
//                             key="submit"
//                             htmlType="submit"
//                             type="primary"
//                             disabled={isLoading}
//                             size="medium">
//                             {isLoading ? 'Loading...' : 'Submit'}
//                         </Button>
//                     ]}
//                 >
//                     <Form
//                         {...layout}
//                         name="Change Password"
//                         form={form}
//                         id="passForm"
//                         validateMessages={validateMessages}
//                         onFinish={handleSubmit}>
//                         <Form.Item
//                             name="currentPassword"
//                             rules={[{ required: true }]}
//                             label="Current Password">
//                             <Input.Password placeholder="Current Password"/>
//                         </Form.Item>
//                         <Form.Item
//                             name="password"
//                             rules={[{ required: true }]}
//                             label="New Password">
//                             <Input.Password placeholder="New Password"/>
//                         </Form.Item>
//                         {/*<Form.Item*/}
//                         {/*    name="confirmPassword"*/}
//                         {/*    rules={[{ required: true, lenCheck: 6, min: 6 }]}*/}
//                         {/*    label="Confirm Password">*/}
//                         {/*    <Input.Password placeholder="Confirm Password"/>*/}
//                         {/*</Form.Item>*/}
//
//                     </Form>
//                 </Modal>
//             </Col>
//             <div className="nav-author">
//                 {Scope.checkScopes(['quick_search_index']) && (
//                     <Link to={`/admin/quick-search`}>
//                     <Button size="small" type="primary" onClick={() =>{}}>
//                         <FeatherIcon icon="search" size={16} />
//                         <small>Quick Search</small>
//                     </Button>
//                     </Link>
//                 )}
//                 <Popover placement="bottomRight" content={userContent} action="click">
//                     <Link to="#" className="head-example">
//                         <Avatar src={userPhotoUrl} size={40}/>
//                     </Link>
//                 </Popover>
//             </div>
//         </InfoWraper>
//     );
// };
//
// export default AuthInfo;
