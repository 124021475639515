const actions = {
    FETCH_CRITERIAS : 'FETCH_CRITERIAS',
    FETCH_CRITERIA  : 'FETCH_CRITERIA',
    RESET_CRITERIA  : 'RESET_CRITERIA',

    fetchCriteriasAction: (data) => {
        return {
            type: actions.FETCH_CRITERIAS,
            data: data
        };
    },
    resetCriteriaFormAction: () => {
        return {
            type: actions.RESET_CRITERIA,
            data: null
        };
    },
    fetchCriteriaAction: (data) => {
        return {
            type: actions.FETCH_CRITERIA,
            data: data
        };
    },
}

export default actions;
