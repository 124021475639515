import actions from "./actions";

const {FETCH_ROUTINE, FETCH_ROUTINES, RESET_ROUTINE, RESET_ROUTINES} = actions;
const initState = {
    routines: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    routine: {}
};

const RoutineReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ROUTINES:
            return {
                ...state,
                routines: data
            }
        case RESET_ROUTINE:
            return {
                ...state,
                routine: initState.routine,
            };
        case RESET_ROUTINES:
            return {
                ...state,
                routines: initState.routines,
            };
        case FETCH_ROUTINE:
            return {
                ...state,
                routine: data,
            };
        default:
            return state;
    }
}

export default RoutineReducer;
