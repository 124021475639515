import actions from "./actions";

const {FETCH_LOGS, FETCH_STUDENT_LOGS} = actions;
const initState = {
    logs: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    studentLogs: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
};

const LogReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_LOGS:
            return {
                ...state,
                logs: data
            }
        case FETCH_STUDENT_LOGS:
            return {
                ...state,
                studentLogs: data
            }
        default:
            return state;
    }
}

export default LogReducer;
