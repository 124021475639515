const actions = {
    FETCH_CAREER_POSTS : 'FETCH_CAREER_POSTS',
    FETCH_CAREER_POST : 'FETCH_CAREER_POST',
    RESET_CAREER_POST : 'RESET_CAREER_POST',
    FETCH_CAREERS : 'FETCH_CAREERS',
    FETCH_MESSAGE : 'FETCH_MESSAGE',
    FETCH_COMMENT : 'FETCH_COMMENT',

    fetchCareersAction: (data) => {
        return {
            type: actions.FETCH_CAREERS,
            data: data
        };
    },
    fetchCareerPostsAction: (data) => {
        return {
            type: actions.FETCH_CAREER_POSTS,
            data: data
        };
    },
    fetchCareerPostAction: (data) => {
        return {
            type: actions.FETCH_CAREER_POST,
            data: data
        };
    },
    resetCareerPostFormAction: () => {
        return {
            type: actions.RESET_CAREER_POST,
            data: null
        };
    },
    fetchMessageAction: (data) => {
        return {
            type: actions.FETCH_MESSAGE,
            data: data
        };
    },
    fetchCommentAction: (data) => {
        return {
            type: actions.FETCH_COMMENT,
            data: data
        };
    },
}

export default actions;
