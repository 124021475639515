const actions = {
    FETCH_POSITIONS : 'FETCH_POSITIONS',
    FETCH_POSITION  : 'FETCH_POSITION',
    RESET_POSITION  : 'RESET_POSITION',

    fetchPositionsAction: (data) => {
        return {
            type: actions.FETCH_POSITIONS,
            data: data
        };
    },
    resetPositionFormAction: () => {
        return {
            type: actions.RESET_POSITION,
            data: null
        };
    },
    fetchPositionAction: (data) => {
        return {
            type: actions.FETCH_POSITION,
            data: data
        };
    },
}

export default actions;
