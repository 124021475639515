const actions = {
    FETCH_HALF_YEARLY : 'FETCH_HALF_YEARLY',

    fetchHalfYearlyAction: (data) => {
        return {
            type: actions.FETCH_HALF_YEARLY,
            data: data
        };
    },
}

export default actions;
