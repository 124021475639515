import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Students = lazy(() => import('../../container/students/all-students/index'));
const IDCard = lazy(() => import('../../container/students/id-card/index'));
const AllStudents = lazy(() => import('../../container/students/academic-students/index'));
const MyStudents = lazy(() => import('../../container/students/my-students/index'));
const MarksStudents = lazy(() => import('../../container/students/marks-students/index'));
const SectionStudents = lazy(() => import('../../container/students/section-students/index'));
const NewStudents = lazy(() => import('../../container/students/new-students/index'));
const Profile = lazy(() => import('../../container/students/profile/index'));

const StudentsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/students`} component={Students}/>
            <Route path={`${path}/new-students`} component={NewStudents}/>
            <Route path={`${path}/id-card`} component={IDCard}/>
            <Route path={`${path}/all-students`} component={AllStudents}/>
            <Route path={`${path}/my-students`} component={MyStudents}/>
            <Route path={`${path}/section-students`} component={SectionStudents}/>
            <Route path={`${path}/marks-students`} component={MarksStudents}/>
            <Route path={`${path}/profile/:id`} component={Profile}/>
        </Switch>
    );
};

export default StudentsRoutes;
