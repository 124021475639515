import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Career = lazy(() => import('../../container/admin/career'));
const CareerPost = lazy(() => import('../../container/admin/careerPost'));
const Message = lazy(() => import('../../container/admin/message'));
const Comment = lazy(() => import('../../container/admin/comment'));

const AdminRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/careerPost`} component={CareerPost}/>
            <Route path={`${path}/career`} component={Career}/>
            <Route path={`${path}/message`} component={Message}/>
            <Route path={`${path}/comment`} component={Comment}/>
        </Switch>
    );
};

export default AdminRoutes;
