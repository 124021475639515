import actions from "./actions";

const {FETCH_NOTE, FETCH_NOTES, RESET_NOTES_FORM, RESET_NOTES_LIST} = actions;
const initState = {
    note: {},
    notes:{},
};

const NoteReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_NOTE:
            return {
                ...state,
                note: data
            }
        case RESET_NOTES_FORM:
            return {
                ...state,
                note: initState.note,
            };
        case RESET_NOTES_LIST:
            return {
                ...state,
                notes: initState.notes,
            };
        case FETCH_NOTES:
            return {
                ...state,
                notes: data
            }
        default:
            return state;
    }
}

export default NoteReducer;
