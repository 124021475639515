const actions = {
    FETCH_PAYMENTS : 'FETCH_PAYMENTS',
    FETCH_PAYMENT : 'FETCH_PAYMENT',

    fetchPaymentsAction: (data) => {
        return {
            type: actions.FETCH_PAYMENTS,
            data: data
        };
    },
    fetchPaymentMonthAction: (data) => {
        return {
            type: actions.FETCH_PAYMENT,
            data: data
        };
    },
}

export default actions;
