import actions from "./actions";

const { GET_MESSAGES } = actions;
const initState = {
    getMessages: {
    page: 0,
        perPage: 35,
        data: [],
        total: 0
},
};

const MessageReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case GET_MESSAGES:
            return {
                ...state,
                getMessages: data
            }
        default:
            return state;
    }
}

export default MessageReducer;
