import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Criteria = lazy(() => import('../../container/kpi/criteria'));
const KpiUsers = lazy(() => import('../../container/kpi/kpi-users'));

const KPIRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/criteria`} component={Criteria}/>
            <Route path={`${path}/kpi-users`} component={KpiUsers}/>
        </Switch>
    );
};

export default KPIRoutes;