import actions from "./actions";

const {FETCH_PERIOD, FETCH_PERIODS, RESET_PERIOD} = actions;
const initState = {
    periods: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    period: {_id: null}
};

const PeriodsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PERIODS:
            return {
                ...state,
                periods: data
            }
        case RESET_PERIOD:
            return {
                ...state,
                period: initState.period,
            };
        case FETCH_PERIOD:
            return {
                ...state,
                period: data,
            };
        default:
            return state;
    }
}

export default PeriodsReducer;
