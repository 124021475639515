const actions = {
    GET_MESSAGES : 'GET_MESSAGES',
    
    fetchMessagesAction: (data) => {
        return {
            type: actions.GET_MESSAGES,
            data: data
        };
    },
}

export default actions;
