const actions = {
    FETCH_SESSIONS : 'FETCH_SESSIONS',
    FETCH_SESSION  : 'FETCH_SESSION',
    RESET_SESSION  : 'RESET_SESSION',

    fetchSessionsAction: (data) => {
        return {
            type: actions.FETCH_SESSIONS,
            data: data
        };
    },
    resetSessionFormAction: () => {
        return {
            type: actions.RESET_SESSION,
            data: null
        };
    },
    fetchSessionAction: (data) => {
        return {
            type: actions.FETCH_SESSION,
            data: data
        };
    },
}

export default actions;
