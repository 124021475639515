const actions = {
    FETCH_CLASS_ROOMS : 'FETCH_CLASS_ROOMS',
    FETCH_CLASS_ROOM  : 'FETCH_CLASS_ROOM',
    RESET_CLASS_ROOM  : 'RESET_CLASS_ROOM',

    fetchClassRoomsAction: (data) => {
        return {
            type: actions.FETCH_CLASS_ROOMS,
            data: data
        };
    },
    resetClassRoomFormAction: () => {
        return {
            type: actions.RESET_CLASS_ROOM,
            data: null
        };
    },
    fetchClassRoomAction: (data) => {
        return {
            type: actions.FETCH_CLASS_ROOM,
            data: data
        };
    },
}

export default actions;
