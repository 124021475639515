import actions from "./actions";

const {FETCH_PERFORMANCE} = actions;
const initState = {
    performance: [],
};

const PerformancesReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PERFORMANCE:
            return {
                ...state,
                performance: data
            }
        default:
            return state;
    }
}

export default PerformancesReducer;
