import actions from "./actions";

const {FETCH_QUARTER} = actions;
const initState = {
    quarter: [],
};

const QuartersReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_QUARTER:
            return {
                ...state,
                quarter: data
            }
        default:
            return state;
    }
}

export default QuartersReducer;
