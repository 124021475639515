import actions from './actions'
import utilitiesAction from '../../utilities/actions'
import {RequestService as req} from "../../../services/requestService";
import { Constants } from "../../../config/constants";
import {fetchClasses} from "../../../redux/utilities/actionCreator";

const { fetchStudentAction, fetchStudentsAction, fetchNewStudentsAction, resetStudentFormAction } = actions;
const {fetchStudentsAction: utilitiesFetchStudentsAction} = utilitiesAction;

export const addStudent = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({
            url: `${Constants.STUDENTS}students`,
            auth: 'bearer',
            body: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchStudents({page: 1,  perPage: 35}));
            if (action) await action();
        })
    };
};

export const fetchStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStudentsAction(cb)))
    };
};

export const fetchNewStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}new/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchNewStudentsAction(cb)))
    };
};

export const fetchFilteredStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}filtered/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStudentsAction(cb)))
    };
};

export const updateStudent = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}students/${data._id}`,
            auth: 'bearer',
            body: data,
            validate
        },  async (cb) => {
            await dispatch(fetchStudents({page: 1,  perPage: 35}));
            if (action) await action();
        })
    };
};

export const resetStudentForm = () => dispatch => dispatch(resetStudentFormAction());

export const fetchStudent = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}students/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            if (cb) dispatch (fetchClasses(cb?.session?._id))
            await dispatch(fetchStudentAction(cb));
            if (action) await action();
            if (cb?.academic?.siblings) dispatch(utilitiesFetchStudentsAction(cb.academic.siblings));
        })
    };
};

export const fetchStudentProfile = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}students/profile/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            await dispatch(fetchStudentAction(cb));
            if (action) await action();
            if (cb?.academic?.siblings) dispatch(utilitiesFetchStudentsAction(cb.academic.siblings));
        })
    };
};

export const checkEmail = (data, validate) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}/students/validate/email`,
            auth: 'bearer',
            queries: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchStudentAction(cb));
        })
    };
};

export const checkPhone = (data, validate) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}/students/validate/phone`,
            auth: 'bearer',
            queries: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchStudentAction(cb));
        })
    };
};

export const deleteStudent = (_id) => {
    return async dispatch => {
        await req.deleteRequest({
            url: `${Constants.STUDENTS}students/${_id}`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStudents({page: 1,  perPage: 35})))
    };
};

export const updateStatus = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}update/students/status`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filteredStudentId) {
                dispatch(fetchFilteredStudents({studentId: data.filterInfo.filteredStudentId}))
            } else {
                dispatch(fetchStudents({page: data.pageInfo.page, perPage: data.pageInfo.perPage}))
            }
        })
    };
};

export const updateSectionStatus = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}update/students/section-status`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            await dispatch(fetchNewStudents ())
        })
    };
};

export const updatePhotoStatus = (data) => {
    const getData = {
        studentInfo: data.studentInfo,
        photosUrl: data.photosUrl,
    }
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}update/students/photo`,
            auth: 'bearer',
            body: getData,
        },  async (cb) => {
            if (data.filterInfo.filteredStudentId) {
                dispatch(fetchFilteredStudents({studentId: data.filterInfo.filteredStudentId}))
            } else {
                dispatch(fetchStudents({page: data.pageInfo.page, perPage: data.pageInfo.perPage}))
            }
        })
    };
};

export const addStudentsInCSV = (data) => {
    return async dispatch => {
        await req.postRequest({url: `${Constants.STUDENTS}students/csv`, auth: 'bearer', body: data,}, async (cb) => {
            await dispatch(fetchStudents({page: 1,  perPage: 35}));
        })
    };
};

export const admittedStudentsInCSV = (data) => {
    return async dispatch => {
        await req.putRequest({url: `${Constants.STUDENTS}students/admitted/csv`, auth: 'bearer', body: data,}, async (cb) => {
            await dispatch(fetchStudents({page: 1,  perPage: 35}));
        })
    };
};



