const actions = {
    FETCH_COORDINATION : 'FETCH_COORDINATION',

    fetchCoordinationAction: (data) => {
        return {
            type: actions.FETCH_COORDINATION,
            data: data
        };
    },
}

export default actions;
