import actions from "./actions";

const {FETCH_MOCK, FETCH_MOCKS, RESET_MOCK} = actions;
const initState = {
    mocks: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    mock: {_id: null}
};

const MockReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_MOCKS:
            return {
                ...state,
                mocks: data
            }
        case RESET_MOCK:
            return {
                ...state,
                mock: initState.mock,
            };
        case FETCH_MOCK:
            return {
                ...state,
                mock: data,
            };
        default:
            return state;
    }
}

export default MockReducer;
