const actions = {
    FETCH_ROUTINES : 'FETCH_ROUTINES',
    FETCH_ROUTINE  : 'FETCH_ROUTINE',
    RESET_ROUTINE  : 'RESET_ROUTINE',
    RESET_ROUTINES  : 'RESET_ROUTINES',

    fetchRoutinesAction: (data) => {
        return {
            type: actions.FETCH_ROUTINES,
            data: data
        };
    },
    resetRoutineFormAction: () => {
        return {
            type: actions.RESET_ROUTINE,
            data: null
        };
    },
    resetRoutinesFormAction: () => {
        return {
            type: actions.RESET_ROUTINES,
            data: {}
        };
    },
    fetchRoutineAction: (data) => {
        return {
            type: actions.FETCH_ROUTINE,
            data: data
        };
    },
}

export default actions;
