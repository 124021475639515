import actions from "./actions";

const {FETCH_ATTENDANCE, FETCH_ATTENDANCE_RESET} = actions;
const initState = {
    attendance: [],
};

const AttendanceReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ATTENDANCE:
            return {
                ...state,
                attendance: data
            }
        case FETCH_ATTENDANCE_RESET:
            return {
                ...state,
                attendance: initState.attendance,
            };
        default:
            return state;
    }
}

export default AttendanceReducer;
