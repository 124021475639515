const actions = {
    FETCH_SECTIONS : 'FETCH_SECTIONS',
    FETCH_SECTION  : 'FETCH_SECTION',
    RESET_SECTION  : 'RESET_SECTION',

    fetchSectionsAction: (data) => {
        return {
            type: actions.FETCH_SECTIONS,
            data: data
        };
    },
    resetSectionFormAction: () => {
        return {
            type: actions.RESET_SECTION,
            data: null
        };
    },
    fetchSectionAction: (data) => {
        return {
            type: actions.FETCH_SECTION,
            data: data
        };
    },
}

export default actions;
