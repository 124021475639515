const actions = {
    FETCH_QUARTER : 'FETCH_QUARTER',

    fetchQuarterAction: (data) => {
        return {
            type: actions.FETCH_QUARTER,
            data: data
        };
    },
}

export default actions;
