import actions from "./actions";

const {FETCH_CALENDER, FETCH_CALENDERS, RESET_CALENDER} = actions;
const initState = {
    calenders: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    calender: {_id: null}
};

const CalenderReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_CALENDERS:
            return {
                ...state,
                calenders: data
            }
        case RESET_CALENDER:
            return {
                ...state,
                calender: initState.calender,
            };
        case FETCH_CALENDER:
            return {
                ...state,
                calender: data,
            };
        default:
            return state;
    }
}

export default CalenderReducer;
