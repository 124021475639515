const actions = {
    FETCH_NOTE : 'FETCH_NOTE',
    FETCH_NOTES : 'FETCH_NOTES',
    RESET_NOTES_FORM : 'RESET_NOTES_FORM',
    RESET_NOTES_LIST : 'RESET_NOTES_LIST',

    fetchNoteAction: (data) => {
        return {
            type: actions.FETCH_NOTE,
            data: data
        };
    },
    resetNotesFormAction: () => {
        return {
            type: actions.RESET_NOTES_FORM,
            data: null
        };
    },
    resetNotesListAction: () => {
        return {
            type: actions.RESET_NOTES_LIST,
            data: null
        };
    },
    fetchNotesAction: (data) => {
        return {
            type: actions.FETCH_NOTES,
            data: data
        };
    },
}

export default actions;
