import actions from "./actions";

const {FETCH_BLOG, FETCH_BLOGS, RESET_BLOG} = actions;
const initState = {
    blogs: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    blog: {_id: null}
};

const BlogReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_BLOGS:
            return {
                ...state,
                blogs: data
            }
        case RESET_BLOG:
            return {
                ...state,
                blog: initState.blog,
            };
        case FETCH_BLOG:
            return {
                ...state,
                blog: data,
            };
        default:
            return state;
    }
}

export default BlogReducer;
