const actions = {
    FETCH_STUDENTS : 'FETCH_STUDENTS',

    fetchStudentsAction: (data) => {
        return {
            type: actions.FETCH_STUDENTS,
            data: data
        };
    },
}

export default actions;
