const actions = {
    FETCH_ATTENDANCE : 'FETCH_ATTENDANCE',
    FETCH_ATTENDANCE_RESET: 'FETCH_ATTENDANCE_RESET',

    fetchAttendanceAction: (data) => {
        return {
            type: actions.FETCH_ATTENDANCE,
            data: data
        };
    },
    resetFetchAttendanceFromAction: (data) => {
        return {
            type: actions.FETCH_ATTENDANCE_RESET,
            data: data
        };
    },
}

export default actions;
