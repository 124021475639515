const actions = {
    FETCH_FINAL : 'FETCH_FINAL',

    fetchFinalAction: (data) => {
        return {
            type: actions.FETCH_FINAL,
            data: data
        };
    },
}

export default actions;
