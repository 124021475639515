import actions from './actions';
import {RequestService as req} from '../../services/requestService';
import { Constants } from '../../config/constants';
const {
    LoadingStart, LoadingEnd,
    fetchRolesAction,
    fetchSessionsAction, fetchClassesAction, fetchFileSizeAction, fetchFileLengthAction,
    fetchUsersAction, fetchHodsAction, fetchStudentsAction, fetchStudentAcademicInfoAction,
    fetchSectionsAction, fetchClassRoomsAction,
    fetchPeriodsAction, fetchSubjectsAction, fetchRoutinesAction, fetchSectionRoutinesAction, fetchAllSubjectsAction,
    fetchSessionAction, resetRoutinesFormAction, fetchSectionPeriodsAction,
    fetchCandidatesAction, fetchMessageCandidatesAction, fetchAcademicInfoAction,
    fetchCoordinatedStudentsAction, fetchCommentCandidatesAction,
    fetchCriteriaAction, fetchPositionsAction,
    resetSectionRoutinesFormAction, resetSectionPeriodsFormAction
} = actions;
const loadingStart = () => async dispatch => dispatch(LoadingStart());
const loadingEnd = () => async dispatch => dispatch(LoadingEnd());

const fetchRoles = () => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}roles`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRolesAction(cb)))
    };
};

const fetchSessions = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}sessions`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSessionsAction(cb))
        })
    };
};

const fetchPositions = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}positions`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchPositionsAction(cb))
        })
    };
};

const fetchCriteria = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}criteria`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchCriteriaAction(cb))
        })
    };
};

const fetchSessionsWithType = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}sessions/type`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSessionAction(cb))
        })
    };
};


const fetchClasses = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}classes`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchClassesAction(cb))
        })
    };
};

const fetchSections = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}sections`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSectionsAction(cb))
        } )
    };
};

const fetchPerformanceSections = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}sections`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSectionsAction(cb))
            const section = cb?.find((item) => item?.name === "A");
            if (section) {
                dispatch(fetchPerformanceSubjects({sessionId: section.session._id, classId: section.class._id, sectionId: section._id}))
                dispatch(fetchPerformanceAllSubjects({sessionId: section.session._id, classId: section.class._id}))
            }
        } )
    };
};


const fetchClassRooms = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}classrooms`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchClassRoomsAction(cb)))
    };
};

const fetchPeriods = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}periods`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchPeriodsAction(cb)))
    };
};

const fetchSubjects = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}subjects`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSubjectsAction(cb)))
    };
};

const fetchPerformanceSubjects = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}subjects`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSubjectsAction(cb)))
    };
};

const fetchPerformanceAllSubjects = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}subjects/all`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchAllSubjectsAction(cb)))
    };
};

const fetchFileSize = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}fileSize`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchFileSizeAction(cb)))
    };
};

const fetchFileLength = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}fileLength`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchFileLengthAction(cb)))
    };
};

const fetchUserSubjects = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}user/subjects`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSubjectsAction(cb)))
    };
};

const fetchTeacherSubjects = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}teacher/subjects`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSubjectsAction(cb)))
    };
};


export const resetUtilityRoutinesForm = () => dispatch => dispatch(resetRoutinesFormAction());

const fetchUsers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}users`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchUsersAction(cb)))
    };
};

const fetchAcademicInfo = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}academicInfo`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchAcademicInfoAction(cb)))
    };
};

const fetchStudentAcademicInfo = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}student/academicInfo`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStudentAcademicInfoAction(cb)))
    };
};

const fetchCandidates = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}candidates`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCandidatesAction(cb)))
    };
};

const fetchMessageCandidatesCandidates = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}messageCandidates`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchMessageCandidatesAction(cb)))
    };
};

const fetchCommentCandidatesCandidates = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}messageCandidates`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCommentCandidatesAction(cb)))
    };
};


const fetchHods = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}users`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchHodsAction(cb)))
    };
};

const fetchStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStudentsAction(cb)))
    };
};

const fetchCoordinatedStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}students/coordinated`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchCoordinatedStudentsAction(cb)))
    };
};

const fetchRoutines = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}routines`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRoutinesAction(cb)))
    };
};

const fetchTeacherRoutines = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}teacher/routines`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRoutinesAction(cb)))
    };
};

const fetchSectionRoutines = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}section/routines`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSectionRoutinesAction(cb)))
    };
};

const fetchSectionPeriods = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}section/periods`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSectionPeriodsAction(cb)))
    };
}

export const resetSectionRoutinesForm = () => dispatch => dispatch(resetSectionRoutinesFormAction());

export const resetSectionPeriodsForm = () => dispatch => dispatch(resetSectionPeriodsFormAction());

export {
    loadingStart, loadingEnd,
    fetchRoles,
    fetchSessions, fetchClasses, fetchSections, fetchFileSize, fetchFileLength, fetchPerformanceSections, fetchTeacherSubjects,
    fetchClassRooms, fetchPeriods, fetchSubjects, fetchUserSubjects,
    fetchUsers, fetchHods, fetchRoutines, fetchSectionRoutines, fetchSectionPeriods,
    fetchSessionsWithType, fetchStudents, fetchTeacherRoutines,
    fetchCandidates, fetchAcademicInfo, fetchStudentAcademicInfo,
    fetchCommentCandidatesCandidates, fetchMessageCandidatesCandidates,
    fetchCoordinatedStudents,
    fetchCriteria, fetchPositions
};
