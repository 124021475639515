import actions from './actions';

const {FETCH_QUICK_STUDENT, RESET_SEARCH} = actions;

const initState = {
    student: {}
};

const QuickStudentReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_QUICK_STUDENT:
            return {
                ...state,
                student: data,
            };
        case RESET_SEARCH:
            return {
                ...state,
                student: initState.student,
            };
        default:
            return state;
    }
};
export default QuickStudentReducer;
