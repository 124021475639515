import actions from "./actions";

const {FETCH_FINAL} = actions;
const initState = {
    final: [],
};

const FinalReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_FINAL:
            return {
                ...state,
                final: data
            }
        default:
            return state;
    }
}

export default FinalReducer;
