const actions = {
    FETCH_KPI_TEACHERS : 'FETCH_KPI_TEACHERS',
    RESET_KPI_TEACHER  : 'RESET_KPI_TEACHER',
    KPI_INFO  : 'KPI_INFO',

    fetchKpiTeachersAction: (data) => {
        return {
            type: actions.FETCH_KPI_TEACHERS,
            data: data
        };
    },
    fetchKpiAction: (data) => {
        return {
            type: actions.KPI_INFO,
            data: data
        };
    },
    resetKpiTeacherFormAction: () => {
        return {
            type: actions.RESET_KPI_TEACHER,
            data: null
        };
    },

}

export default actions;
