const actions = {
    FETCH_LOGS : 'FETCH_LOGS',
    FETCH_STUDENT_LOGS : 'FETCH_STUDENT_LOGS',

    fetchLogsAction: (data) => {
        return {
            type: actions.FETCH_LOGS,
            data: data
        };
    },
    fetchStudentLogsAction: (data) => {
        return {
            type: actions.FETCH_STUDENT_LOGS,
            data: data
        };
    },
}

export default actions;