import actions from './actions';

const {FETCH_KPI_TEACHERS, RESET_KPI_TEACHER, KPI_INFO} = actions;
const initState = {
    kpiTeachers: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    kpiInfo: {_id: null}
};

const KpiTeacherReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_KPI_TEACHERS:
            return {
                ...state,
                kpiTeachers: data,
            };
        case RESET_KPI_TEACHER:
            return {
                ...state,
                kpiTeacher: initState.kpiTeacher
            };
        case KPI_INFO:
            return {
                ...state,
                kpiInfo: data,
            };
        default:
            return state;
    }
};

export default KpiTeacherReducer;
