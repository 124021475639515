import {Constants} from '../../../config/constants';
import actions from './actions';
import {RequestService as req} from '../../../services/requestService';
import {fetchFilteredStudents, fetchStudents} from "../../students/students/actionCreator";
import {fetchAllStudents} from "../../students/all-students/actionCreator";

const {fetchUsersAction, resetUserFormAction, fetchUserAction} = actions;

export const addUser = (data, action) => {
    return async dispatch => {
        await req.postRequest({
            url: `${Constants.USER_MANAGEMENT}users`,
            auth: 'bearer',
            body: data
        }, async (cb) => {
            await dispatch(fetchUsers({page: 1,  perPage: 35}));
            await dispatch(action);
        })
    };
};

export const updateUser = (data, action) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.USER_MANAGEMENT}users/${data._id}`,
            auth: 'bearer',
            body: data
        }, async (cb) => {
            await dispatch(fetchUsers({page: 1,  perPage: 35}));
            await dispatch(action);
        })
    };
};

export const updatePhotoStatus = (data) => {
    const getData = {
        teacherInfo: data.teacherInfo,
        photosUrl: data.photosUrl,
    }
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.USER_MANAGEMENT}update/user/photo`,
            auth: 'bearer',
            body: getData,
        },  async (cb) => {
            if (data.filterInfo.filteredTeacherId) {
                dispatch(fetchUsers({teacherId: data.filterInfo.filteredTeacherId}))
            } else {
                dispatch(fetchUsers({page: data.pageInfo.page, perPage: data.pageInfo.perPage}))
            }
        })
    };
};

export const userPasswordReset = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.USER_MANAGEMENT}users/reset/password`,
            auth: 'bearer',
            body: data.userInformation,
        },  async (cb) => {
            if (data.userInformation.teacherId) {
                dispatch(fetchUsers({page: data.pageInfo.page, perPage: data.pageInfo.perPage, teacherId: data.userInformation.teacherId}))
            }
        })
    };
};

export const userStatusUpdate = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.USER_MANAGEMENT}users/update/status`,
            auth: 'bearer',
            body: data.userInformation,
        },  async (cb) => {
            if (data.userInformation.teacherId) {
                dispatch(fetchUsers({page: data.pageInfo.page, perPage: data.pageInfo.perPage, teacherId: data.userInformation.teacherId}))
            }
        })
    };
};

export const updateProfilePhotoStatus = (data) => {
    const getData = {
        teacherInfo: data.teacherInfo,
        photosUrl: data.photosUrl,
    }
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.USER_MANAGEMENT}update/teacher/photo`,
            auth: 'bearer',
            body: getData,
        },  async (cb) => {
          if (data.teacherInfo.teacherId)  dispatch (fetchProfileUser(data.teacherInfo.teacherId))
        })
    };
};

export const resetUserForm = () => dispatch => dispatch(resetUserFormAction());
export const fetchUser = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.USER_MANAGEMENT}users/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            await dispatch(fetchUserAction(cb));
           if (action) await dispatch(action);
        })
    };
};

export const fetchPhotoUser = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.USER_MANAGEMENT}teacher/photo/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            await dispatch(fetchUserAction(cb));
            if (action) await dispatch(action);
        })
    };
};


export const fetchProfileUser = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.USER_MANAGEMENT}profile/user/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            await dispatch(fetchUserAction(cb));
            if (action) await dispatch(action);
        })
    };
};

export const deleteUser = (_id) => {
    return async dispatch => {
        await req.deleteRequest({
            url: `${Constants.USER_MANAGEMENT}users/${_id}`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchUsers({page: 1,  perPage: 35})))
    };
};

export const fetchUsers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.USER_MANAGEMENT}users`,
            auth: 'bearer',
            queries: queries
        }, async (cb) => {
            await dispatch(fetchUsersAction(cb));
        })
    }
};

export const addUsersInCSV = (data) => {
    return async dispatch => {
        await req.postRequest({url: `${Constants.USER_MANAGEMENT}users/csv`, auth: 'bearer', body: data,}, async (cb) => {})
    };
};
