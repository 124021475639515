import actions from "./actions";

const {FETCH_STUDENTS} = actions;
const initState = {
    students: []
};

const CardReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_STUDENTS:
            return {
                ...state,
                students: data
            }
        default:
            return state;
    }
}

export default CardReducer;
