import actions from "./actions";

const {FETCH_HALF_YEARLY_REPORT} = actions;
const initState = {
    halfYearlyReports: [],
};

const HalfYearlyReportReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_HALF_YEARLY_REPORT:
            return {
                ...state,
                halfYearlyReports: data
            }
        default:
            return state;
    }
}

export default HalfYearlyReportReducer;
