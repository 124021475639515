import actions from "./actions";

const {FETCH_SESSION, FETCH_SESSIONS, RESET_SESSION} = actions;
const initState = {
    sessions: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    session: {_id: null}
};

const SessionReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SESSIONS:
            return {
                ...state,
                sessions: data
            }
        case RESET_SESSION:
            return {
                ...state,
                session: initState.session,
            };
        case FETCH_SESSION:
            return {
                ...state,
                session: data,
            };
        default:
            return state;
    }
}

export default SessionReducer;
