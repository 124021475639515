const actions = {
    FETCH_ALL_STUDENTS_RESULTS_ACCESS : 'FETCH_ALL_STUDENTS_RESULTS_ACCESS',

    fetchAllStudentsResultAccessAction: (data) => {
        return {
            type: actions.FETCH_ALL_STUDENTS_RESULTS_ACCESS,
            data: data
        };
    },
}

export default actions;