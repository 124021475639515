import actions from "./actions";

const {FETCH_POSITION, FETCH_POSITIONS, RESET_POSITION} = actions;
const initState = {
    positions: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    position: {_id: null}
};

const PositionReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_POSITIONS:
            return {
                ...state,
                positions: data
            }
        case RESET_POSITION:
            return {
                ...state,
                position: initState.position,
            };
        case FETCH_POSITION:
            return {
                ...state,
                position: data,
            };
        default:
            return state;
    }
}

export default PositionReducer;