import actions from "./actions";

const {FETCH_CRITERIA, FETCH_CRITERIAS, RESET_CRITERIA} = actions;
const initState = {
    criterias: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    criteria: {_id: null}
};

const CriteriaReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_CRITERIAS:
            return {
                ...state,
                criterias: data
            }
        case RESET_CRITERIA:
            return {
                ...state,
                criteria: initState.criteria,
            };
        case FETCH_CRITERIA:
            return {
                ...state,
                criteria: data,
            };
        default:
            return state;
    }
}

export default CriteriaReducer;