const actions = {
    FETCH_REPORT_MOCK : 'FETCH_REPORT_MOCK',

    fetchReportMockAction: (data) => {
        return {
            type: actions.FETCH_REPORT_MOCK,
            data: data
        };
    },
}

export default actions;
