const actions = {
    RESET_DASHBOARDS : 'RESET_DASHBOARDS',
    INVOICE_STUDENTS_USERS : 'INVOICE_STUDENTS_USERS',
    GET_INVOICES : 'GET_INVOICES',
    GET_INVOICE : 'GET_INVOICE',

    fetchInvoiceStudentsUsersAction: (data) => {
        return {
            type: actions.INVOICE_STUDENTS_USERS,
            data: data
        };
    },
    fetchInvoicesAction: (data) => {
        return {
            type: actions.GET_INVOICES,
            data: data
        };
    },
    fetchInvoiceAction: (data) => {
        return {
            type: actions.GET_INVOICE,
            data: data
        };
    },
    resetDashboardsFormAction: () => {
        return {
            type: actions.RESET_DASHBOARDS,
            data: null
        };
    },
}

export default actions;
