import React from 'react';
import {Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import {Scope} from "../services/scopeService";
import {Auth} from "../services/authService";
import {
    UsergroupAddOutlined
} from '@ant-design/icons';
const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
    const { path } = useRouteMatch();
    const userInfo = Auth.getUserInfo();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');

    return (
        <Menu
            mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
            theme={darkMode && 'dark'}
            // // eslint-disable-next-line no-nested-ternary
            defaultSelectedKeys={
                !topMenu
                    ? [
                        `${
                            mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                        }`,
                    ]
                    : []
            }
            defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        >
            {Scope.checkScopes(['dashboard_index']) ? (
                <>
                    <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="dashboard">
                        <NavLink to={`${path}/dashboard`}>
                            Dashboard
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item icon={!topMenu && <FeatherIcon icon="user" />} key="profile">
                        <NavLink to={`${path}/user-management/profile/${userInfo?._id}`}>
                            Profile
                        </NavLink>
                    </Menu.Item>
                </>

            ) : (
                <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="profile">
                <NavLink to={`${path}/user-management/profile/${userInfo?._id}`}>
                Profile
                </NavLink>
                </Menu.Item>
                )}

            {Scope.checkScopesParent(['um_']) ? (
                <SubMenu key="user-management" icon={!topMenu && <FeatherIcon icon="maximize" />} title="Administration">
                    {Scope.checkScopes(['um_position_index']) && (
                        <Menu.Item key="position">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/position`}>
                                Positions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_departments_index']) && (
                        <Menu.Item key="departments">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/departments`}>
                                Departments
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_roles_index']) && (
                        <Menu.Item key="roles">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/roles`}>
                                Roles
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_roles_permissions_index']) && (
                        <Menu.Item key="roles-permissions">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/roles-permissions`}>
                                Permissions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_users_index']) && (
                        <Menu.Item key="um-users">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/users`}>
                                Users
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['students_portal_result_access']) && (
                        <Menu.Item key="um-access">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/student-access`}>
                                Access Control
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_logs_index']) && (
                        <Menu.Item key="logs">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/logs`}>
                               User Logs
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['um_logs_index']) && (
                        <Menu.Item key="studentLogs">
                            <NavLink onClick={toggleCollapsed} to={`${path}/user-management/student-logs`}>
                                Student Logs
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null}
            {Scope.checkScopesParent(['admin_']) ? (
                <SubMenu key="admin-management" icon={!topMenu && <FeatherIcon icon="file-plus" />} title="Admin Office">
                    {Scope.checkScopes(['admin_career_index']) && (
                        <Menu.Item key="careerPost">
                            <NavLink onClick={toggleCollapsed} to={`${path}/admin/careerPost`}>
                                Vacancy Post
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['admin_career_index']) && (
                        <Menu.Item key="career">
                            <NavLink onClick={toggleCollapsed} to={`${path}/admin/career`}>
                                CV List
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['admin_message_index']) && (
                        <Menu.Item key="message">
                            <NavLink onClick={toggleCollapsed} to={`${path}/admin/message`}>
                                Message List
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['admin_comment_index']) && (
                        <Menu.Item key="comment">
                            <NavLink onClick={toggleCollapsed} to={`${path}/admin/comment`}>
                                Comments & Opinion
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null}

            {Scope.checkScopesParent(['kpi_']) ? (
                <SubMenu key="kpi" icon={!topMenu && <FeatherIcon icon="loader" />} title="KPI System">
                    {Scope.checkScopes(['kpi_criteria_index']) && (
                        <Menu.Item key="criteria">
                            <NavLink onClick={toggleCollapsed} to={`${path}/kpi/criteria`}>
                                KPI Criteria
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['kpi_index']) && (
                        <Menu.Item key="kpi-users">
                            <NavLink onClick={toggleCollapsed} to={`${path}/kpi/kpi-users`}>
                                KPI Users
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null }

            {Scope.checkScopesParent(['schedules_']) ? (
                <SubMenu key="schedulesManagement" icon={!topMenu && <FeatherIcon icon="loader" />} title="Schedule Management">
                    {Scope.checkScopes(['schedules_index']) && (
                        <Menu.Item key="schedules">
                            <NavLink onClick={toggleCollapsed} to={`${path}/schedules`}>
                                Schedules
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null }

            {Scope.checkScopesParent(['blogs_']) ? (
                <SubMenu key="blog-management" icon={!topMenu && <FeatherIcon icon="loader" />} title="News & Events">
                    {Scope.checkScopes(['blogs_index']) && (
                        <Menu.Item key="blogs">
                            <NavLink onClick={toggleCollapsed} to={`${path}/blogs/blogs`}>
                                Blogs
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null }
            {Scope.checkScopesParent(['admitted_']) ? (
                <SubMenu key="student-management" icon={!topMenu && <FeatherIcon icon="users" />} title="Students">
                    {Scope.checkScopes(['admitted_students_index']) && (
                        <Menu.Item key="students">
                            <NavLink onClick={toggleCollapsed} to={`${path}/students/students`}>
                                All Students
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_Students_index']) && (
                        <Menu.Item key="all-students">
                            <NavLink onClick={toggleCollapsed} to={`${path}/students/all-students`}>
                                Academic Students
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['student_marks_entry_students_index']) && (
                        <Menu.Item key="marks-students">
                            <NavLink onClick={toggleCollapsed} to={`${path}/students/marks-students`}>
                                Marks Entry Form
                            </NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
            ) : null}
            {Scope.checkScopesParent(['academic_']) ? (
                <SubMenu key="academic-management" icon={!topMenu && <FeatherIcon icon="book-open" />} title="Academic">
                    {Scope.checkScopesParent(['academic_setup_']) ? (
                        <SubMenu key="setup-management" title="Teachers" style={{paddingLeft: '35px'}}>
                            {Scope.checkScopes(['academic_setup_class_rooms_index']) && (
                                <Menu.Item key="classrooms">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/classrooms`}>
                                        Classrooms
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_periods_index']) && (
                                <Menu.Item key="periods">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/periods`}>
                                        Periods
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_sessions_index']) && (
                                <Menu.Item key="sessions">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/sessions`}>
                                        Sessions
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_classes_index']) && (
                                <Menu.Item key="classes">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/classes`}>
                                        Classes
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_sections_index']) && (
                                <Menu.Item key="sections">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/sections`}>
                                        Sections
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_subjects_index']) && (
                                <Menu.Item key="subjects">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/subjects`}>
                                        Subjects
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_setup_payment_types_index']) && (
                                <Menu.Item key="paymentTypes">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/setup/payment-types`}>
                                        Payment Types
                                    </NavLink>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    ) : null}
                    {Scope.checkScopes(['academic_routines_index']) && (
                        <Menu.Item key="routines">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/routines`}>
                                Routines
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_coordination_index']) && (
                        <Menu.Item key="coordination">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/coordination`}>
                                Coordination
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_notices_index']) && (
                        <Menu.Item key="notices">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/notice`}>
                                Class Teacher Zone
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_notes_index']) && (
                        <Menu.Item key="notes">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/note`}>
                                Subject Notes
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_attendance_index']) && (
                        <Menu.Item key="attendance">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/attendance`}>
                                Attendance
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_payments_index']) && (
                        <Menu.Item key="payments">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/payments`}>
                                Payments
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['academic_transactions_index']) && (
                        <Menu.Item key="transactions">
                            <NavLink onClick={toggleCollapsed} to={`${path}/academic/transactions`}>
                                Transactions
                            </NavLink>
                        </Menu.Item>
                    )}
                    {/*{Scope.checkScopes(['academic_calendars_index']) && (*/}
                    {/*    <Menu.Item key="calenders">*/}
                    {/*        <NavLink onClick={toggleCollapsed} to={`${path}/academic/calender`}>*/}
                    {/*            Calender*/}
                    {/*        </NavLink>*/}
                    {/*    </Menu.Item>*/}
                    {/*)}*/}

                    {Scope.checkScopes(['academic_my_students_index']) && (
                        <Menu.Item key="myStudents">
                            <NavLink onClick={toggleCollapsed} to={`${path}/students/my-students`}>
                                My Students
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['student_id_card_index']) && (
                        <Menu.Item key="id-card">
                            <NavLink onClick={toggleCollapsed} to={`${path}/students/id-card`}>
                                ID Cards
                            </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopesParent(['academic_result_']) ? (
                        <SubMenu key="result-management" title="Marks" style={{paddingLeft: '35px'}}>
                            {Scope.checkScopes(['academic_result_quarter_index']) && (
                                <Menu.Item key="quarter">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/result/quarter`}>
                                        Quarter
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_result_half_yearly_index']) && (
                                <Menu.Item key="halfYearly">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/result/half-yearly`}>
                                        Half Yearly
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_result_final_index']) && (
                                <Menu.Item key="final">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/result/final`}>
                                        Final
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_result_mock_index']) && (
                                <Menu.Item key="mock">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/result/mock`}>
                                        Mock
                                    </NavLink>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    ) : null}
                    {Scope.checkScopesParent(['academic_reports_']) ? (
                        <SubMenu key="reports-management" title="Reports" style={{paddingLeft: '35px'}}>
                            {Scope.checkScopes(['academic_reports_quarter_index']) && (
                                <Menu.Item key="quarter-report">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/reports/quarter`}>
                                        Quarter
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_reports_half_yearly_index']) && (
                                <Menu.Item key="half-yearly-report">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/reports/half-yearly`}>
                                        Half Yearly
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_reports_final_index']) && (
                                <Menu.Item key="final-report">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/reports/final`}>
                                        Final
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_reports_mock_index']) && (
                                <Menu.Item key="mock-report">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/reports/mock`}>
                                        Mock
                                    </NavLink>
                                </Menu.Item>
                            )}
                            {Scope.checkScopes(['academic_reports_students_performance_index']) && (
                                <Menu.Item key="performance-report">
                                    <NavLink onClick={toggleCollapsed} to={`${path}/academic/reports/performance`}>
                                        Performance
                                    </NavLink>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    ) : null}
                </SubMenu>
            ) : null}
            {Scope.checkScopes(['academic_section_students_index']) && (
                <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="sectionStudents">
                    <NavLink onClick={toggleCollapsed} to={`${path}/students/section-students`}>
                        Students
                    </NavLink>
                </Menu.Item>
            )}
            {Scope.checkScopes(['new_students_index']) && (
                <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="newStudents">
                    <NavLink onClick={toggleCollapsed} to={`${path}/students/new-students`}>
                        New Students
                    </NavLink>
                </Menu.Item>
            )}
            {Scope.checkScopes(['student_fingerprint_sms_index']) && (
                <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="message">
                    <NavLink onClick={toggleCollapsed} to={`${path}/message`}>
                        SMS
                    </NavLink>
                </Menu.Item>
            )}
            {Scope.checkScopes(['invoice_index']) && (
                <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="invoice">
                    <NavLink onClick={toggleCollapsed} to={`${path}/invoice`}>
                        Invoice
                    </NavLink>
                </Menu.Item>
            )}
        </Menu>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
};

export default MenuItems;
