import actions from "./actions";

const {FETCH_CLASS_ROOM, FETCH_CLASS_ROOMS, RESET_CLASS_ROOM} = actions;
const initState = {
    classRooms: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    classRoom: {_id: null}
};

const ClassRoomReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_CLASS_ROOMS:
            return {
                ...state,
                classRooms: data
            }
        case RESET_CLASS_ROOM:
            return {
                ...state,
                classRoom: initState.classRoom,
            };
        case FETCH_CLASS_ROOM:
            return {
                ...state,
                classRoom: data,
            };
        default:
            return state;
    }
}

export default ClassRoomReducer;
