import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ClassRooms = lazy(() => import('../../container/academic/setup/classRooms/index'));
const Subjects = lazy(() => import('../../container/academic/setup/subjects/index'));
const Periods = lazy(() => import('../../container/academic/setup/periods/index'));
const Sessions = lazy(() => import('../../container/academic/setup/sessions/index'));
const Classes = lazy(() => import('../../container/academic/setup/classes/index'));
const Sections = lazy(() => import('../../container/academic/setup/sections/index'));
const PaymentTypes = lazy(() => import('../../container/academic/setup/paymentTypes/index'));

const Routines = lazy(() => import('../../container/academic/routines/index'));
const Coordination = lazy(() => import('../../container/academic/coordination/index'));
const Notice = lazy(() => import('../../container/academic/notices/index'));
const Note = lazy(() => import('../../container/academic/notes/index'));
const Attendance = lazy(() => import('../../container/academic/attendance/index'));
const Payment = lazy(() => import('../../container/academic/payments/index'));
const Transaction = lazy(() => import('../../container/academic/transactions/index'));
const Calender = lazy(() => import('../../container/academic/calender/index'));

const Mock = lazy(() => import('../../container/academic/result/mock/index'));
const Quarter = lazy(() => import('../../container/academic/result/quarter/index'));
const HalfYearly = lazy(() => import('../../container/academic/result/halfYearly/index'));
const Final = lazy(() => import('../../container/academic/result/final/index'));

const reportMock = lazy(() => import('../../container/academic/reports/mock/index'));
const reportQuarter = lazy(() => import('../../container/academic/reports/quarter/index'));
const reportFinal = lazy(() => import('../../container/academic/reports/final/index'));
const reportHalfYearlyReport = lazy(() => import('../../container/academic/reports/halfYearly/index'));

const performance = lazy(() => import('../../container/academic/reports/performance'));

const StudentsRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/setup/classrooms`} component={ClassRooms}/>
            <Route path={`${path}/setup/subjects`} component={Subjects}/>
            <Route path={`${path}/setup/periods`} component={Periods}/>
            <Route path={`${path}/setup/sessions`} component={Sessions}/>
            <Route path={`${path}/setup/classes`} component={Classes}/>
            <Route path={`${path}/setup/sections`} component={Sections}/>
            <Route path={`${path}/setup/payment-types`} component={PaymentTypes}/>

            <Route path={`${path}/routines`} component={Routines}/>
            <Route path={`${path}/coordination`} component={Coordination}/>
            <Route path={`${path}/notice`} component={Notice}/>
            <Route path={`${path}/note`} component={Note}/>
            <Route path={`${path}/attendance`} component={Attendance}/>
            <Route path={`${path}/payments`} component={Payment}/>
            <Route path={`${path}/transactions`} component={Transaction}/>
            <Route path={`${path}/calender`} component={Calender}/>

            <Route path={`${path}/result/mock`} component={Mock}/>
            <Route path={`${path}/result/quarter`} component={Quarter}/>
            <Route path={`${path}/result/half-yearly`} component={HalfYearly}/>
            <Route path={`${path}/result/final`} component={Final}/>

            <Route path={`${path}/reports/mock`} component={reportMock}/>
            <Route path={`${path}/reports/quarter`} component={reportQuarter}/>
            <Route path={`${path}/reports/final`} component={reportFinal}/>
            <Route path={`${path}/reports/half-yearly`} component={reportHalfYearlyReport}/>

            <Route path={`${path}/reports/performance`} component={performance}/>
        </Switch>
    );
};

export default StudentsRoutes;
