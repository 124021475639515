const actions = {
    FETCH_CLASSES : 'FETCH_CLASSES',
    FETCH_CLASS  : 'FETCH_CLASS',
    RESET_CLASS  : 'RESET_CLASS',

    fetchClassesAction: (data) => {
        return {
            type: actions.FETCH_CLASSES,
            data: data
        };
    },
    resetClassFormAction: () => {
        return {
            type: actions.RESET_CLASS,
            data: null
        };
    },
    fetchClassAction: (data) => {
        return {
            type: actions.FETCH_CLASS,
            data: data
        };
    },
}

export default actions;
