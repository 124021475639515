import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Search = lazy(() => import('../../container/search'));

const SearchRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/quick-search`} component={Search}/>
        </Switch>
    );
};

export default SearchRoutes;