const actions = {
    FETCH_BLOGS : 'FETCH_BLOGS',
    FETCH_BLOG  : 'FETCH_BLOG',
    RESET_BLOG  : 'RESET_BLOG',

    fetchBlogsAction: (data) => {
        return {
            type: actions.FETCH_BLOGS,
            data: data
        };
    },
    resetBlogFormAction: () => {
        return {
            type: actions.RESET_BLOG,
            data: null
        };
    },
    fetchBlogAction: (data) => {
        return {
            type: actions.FETCH_BLOG,
            data: data
        };
    },
}

export default actions;
