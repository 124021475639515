import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Roles = lazy(() => import('../../container/userManagement/roles/index'));
const RolesPermissions = lazy(() => import('../../container/userManagement/rolesPermissions'));
const Users = lazy(() => import('../../container/userManagement/users/index'));
const Profile = lazy(() => import('../../container/userManagement/profile/index'));
const teachersProfile = lazy(() => import('../../container/userManagement/teachers/profile/index'));
const departments = lazy(() => import('../../container/userManagement/departments'))
const studentAccess = lazy(() => import('../../container/userManagement/access/studentAccess'))
const password = lazy(() => import('../../container/userManagement/password'))
const logs = lazy(() => import('../../container/userManagement/logs'))
const studentLogs = lazy(() => import('../../container/userManagement/student-logs'))
const position = lazy(() => import('../../container/userManagement/position'))

const UserManagementRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/roles`} component={Roles}/>
            <Route path={`${path}/roles-permissions`} component={RolesPermissions}/>
            <Route path={`${path}/users`} component={Users}/>
            <Route path={`${path}/profile/:id`} component={Profile}/>
            <Route path={`${path}/teachers/profile/:id`} component={teachersProfile}/>
            <Route path={`${path}/departments`} component={departments}/>
            <Route path={`${path}/student-access`} component={studentAccess}/>
            <Route path={`${path}/password`} component={password}/>
            <Route path={`${path}/logs`} component={logs}/>
            <Route path={`${path}/student-logs`} component={studentLogs}/>
            <Route path={`${path}/position`} component={position}/>
        </Switch>
    );
};

export default UserManagementRoutes;
