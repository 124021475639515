const actions = {
    FETCH_NOTICE : 'FETCH_NOTICE',
    FETCH_NOTICES : 'FETCH_NOTICES',
    RESET_NOTICE_FORM : 'RESET_NOTICE_FORM',
    RESET_NOTICE_LIST : 'RESET_NOTICE_LIST',

    fetchNoticeAction: (data) => {
        return {
            type: actions.FETCH_NOTICE,
            data: data
        };
    },
    fetchNoticesAction: (data) => {
        return {
            type: actions.FETCH_NOTICES,
            data: data
        };
    },
    resetNoticeFormAction: (data) => {
        return {
            type: actions.RESET_NOTICE_FORM,
            data: data
        };
    },
    resetNoticeListAction: (data) => {
        return {
            type: actions.RESET_NOTICE_LIST,
            data: data
        };
    },
}

export default actions;
