const actions = {
    FETCH_QUICK_STUDENT    : 'FETCH_QUICK_STUDENT',
    RESET_SEARCH    : "RESET_SEARCH",

    fetchStudentAction: (data) => {
        return {
            type: actions.FETCH_QUICK_STUDENT,
            data: data
        };
    },
    resetQuickSearchFormAction: () => {
        return {
            type: actions.RESET_SEARCH,
            data: null
        };
    },
};

export default actions;
