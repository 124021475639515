import actions from "./actions";

const {FETCH_COORDINATION} = actions;
const initState = {
    coordination: [],
};

const CoordinationReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_COORDINATION:
            return {
                ...state,
                coordination: data
            }
        default:
            return state;
    }
}

export default CoordinationReducer;
