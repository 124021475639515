import actions from "./actions";

const {FETCH_DASHBOARD, FETCH_DASHBOARDS, RESET_DASHBOARDS, DASHBOARDS_STUDENTS} = actions;
const initState = {
    dashboards:{},
    dashboardStudents: {},
};

const DashboardReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_DASHBOARDS:
            return {
                ...state,
                dashboards: data
            }
        case DASHBOARDS_STUDENTS:
            return {
                ...state,
                dashboardStudents: data
            }
        default:
            return state;
    }
}

export default DashboardReducer;
