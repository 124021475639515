const actions = {
    FETCH_STUDENTS : 'FETCH_STUDENTS',
    FETCH_NEW_STUDENTS : 'FETCH_NEW_STUDENTS',
    FETCH_STUDENT  : 'FETCH_STUDENT',
    RESET_STUDENT  : 'RESET_STUDENT',

    fetchStudentsAction: (data) => {
        return {
            type: actions.FETCH_STUDENTS,
            data: data
        };
    },
    fetchNewStudentsAction: (data) => {
        return {
            type: actions.FETCH_NEW_STUDENTS,
            data: data
        };
    },
    resetStudentFormAction: () => {
        return {
            type: actions.RESET_STUDENT,
            data: null
        };
    },
    fetchStudentAction: (data) => {
        return {
            type: actions.FETCH_STUDENT,
            data: data
        };
    },
}

export default actions;
