import actions from "./actions";

const {FETCH_CAREERS, FETCH_MESSAGE, FETCH_CAREER_POSTS, FETCH_CAREER_POST, RESET_CAREER_POST, FETCH_COMMENT} = actions;
const initState = {
    careers: [],
    message: [],
    comment: [],
    careerPosts: [],
    careerPost: {},
};

const CareerReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_CAREERS:
            return {
                ...state,
                careers: data
            }
        case FETCH_MESSAGE:
            return {
                ...state,
                message: data
            }
        case FETCH_COMMENT:
            return {
                ...state,
                comment: data
            }
        case FETCH_CAREER_POSTS:
            return {
                ...state,
                careerPosts: data
            }
        case FETCH_CAREER_POST:
            return {
                ...state,
                careerPost: data
            }
        case RESET_CAREER_POST:
            return {
                ...state,
                careerPost: initState.careerPost,
            };
        default:
            return state;
    }
}

export default CareerReducer;
