const actions = {
    FETCH_CALENDERS : 'FETCH_CALENDERS',
    FETCH_CALENDER  : 'FETCH_CALENDER',
    RESET_CALENDER  : 'RESET_CALENDER',

    fetchCalendersAction: (data) => {
        return {
            type: actions.FETCH_CALENDERS,
            data: data
        };
    },
    resetCalenderFormAction: () => {
        return {
            type: actions.RESET_CALENDER,
            data: null
        };
    },
    fetchCalenderAction: (data) => {
        return {
            type: actions.FETCH_CALENDER,
            data: data
        };
    },
}

export default actions;
