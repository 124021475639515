const actions = {
    FETCH_HALF_YEARLY_REPORT : 'FETCH_HALF_YEARLY_REPORT',

    fetchHalfYearlyReportAction: (data) => {
        return {
            type: actions.FETCH_HALF_YEARLY_REPORT,
            data: data
        };
    },
}

export default actions;
