import actions from "./actions";

const {FETCH_SCHEDULE, FETCH_SCHEDULES, RESET_SCHEDULE} = actions;
const initState = {
    schedules: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    schedule: {_id: null}
};

const ScheduleReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SCHEDULES:
            return {
                ...state,
                schedules: data
            }
        case RESET_SCHEDULE:
            return {
                ...state,
                schedule: initState.schedule,
            };
        case FETCH_SCHEDULE:
            return {
                ...state,
                schedule: data,
            };
        default:
            return state;
    }
}

export default ScheduleReducer;