const actions = {
    FETCH_MOCK : 'FETCH_MOCK',

    fetchMockAction: (data) => {
        return {
            type: actions.FETCH_MOCK,
            data: data
        };
    },
}

export default actions;
