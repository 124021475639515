const actions = {
    FETCH_DASHBOARD : 'FETCH_DASHBOARD',
    FETCH_DASHBOARDS : 'FETCH_DASHBOARDS',
    RESET_DASHBOARDS : 'RESET_DASHBOARDS',
    DASHBOARDS_STUDENTS : 'DASHBOARDS_STUDENTS',

    fetchDashboardAction: (data) => {
        return {
            type: actions.FETCH_DASHBOARD,
            data: data
        };
    },
    fetchDashboardStudentsAction: (data) => {
        return {
            type: actions.DASHBOARDS_STUDENTS,
            data: data
        };
    },
    resetDashboardsFormAction: () => {
        return {
            type: actions.RESET_DASHBOARDS,
            data: null
        };
    },
    fetchDashboardsAction: (data) => {
        return {
            type: actions.FETCH_DASHBOARDS,
            data: data
        };
    },
}

export default actions;
