import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Blogs = lazy(() => import('../../container/blogs'));

const BlogRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/blogs`} component={Blogs}/>
        </Switch>
    );
};

export default BlogRoutes;