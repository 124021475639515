import actions from "./actions";

const {FETCH_SECTION, FETCH_SECTIONS, RESET_SECTION} = actions;
const initState = {
    sections: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    section: {_id: null}
};

const SectionReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SECTIONS:
            return {
                ...state,
                sections: data
            }
        case RESET_SECTION:
            return {
                ...state,
                section: initState.section,
            };
        case FETCH_SECTION:
            return {
                ...state,
                section: data,
            };
        default:
            return state;
    }
}

export default SectionReducer;
