import actions from "./actions";

const {FETCH_SUBJECT, FETCH_SUBJECTS, RESET_SUBJECT} = actions;
const initState = {
    subjects: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    subject: {_id: null}
};

const SubjectsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SUBJECTS:
            return {
                ...state,
                subjects: data
            }
        case RESET_SUBJECT:
            return {
                ...state,
                subject: initState.subject,
            };
        case FETCH_SUBJECT:
            return {
                ...state,
                subject: data,
            };
        default:
            return state;
    }
}

export default SubjectsReducer;
