import actions from './actions'
import {RequestService as req} from "../../../services/requestService";
import { Constants } from "../../../config/constants";
const { fetchAllStudentsAction, fetchMyStudentsAction, fetchMarksEntryStudentsAction, fetchSectionStudentsAction } = actions;

export const fetchAllStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}all/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchAllStudentsAction(cb)))
    };
};

export const fetchSectionStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}section/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSectionStudentsAction(cb)))
    };
};

export const updateStudentFingerprint = (data, action) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.ACADEMIC}student/fingerprint`,
            body: data,
            auth: 'bearer'
        }, async (cb)  => {
            await dispatch(fetchSectionStudents(cb))
            if (action) await action();
        })
    };
};

export const updateStudentCard = (data, action) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.ACADEMIC}student/card`,
            body: data,
            auth: 'bearer'
        }, async (cb)  => {
            await dispatch(fetchSectionStudents(cb))
            if (action) await action();
        })
    };
};

export const updateStudentSMSOff = (data, action) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.ACADEMIC}student/sms/off`,
            body: data,
            auth: 'bearer'
        }, async (cb)  => {
            await dispatch(fetchSectionStudents(cb))
            if (action) await action();
        })
    };
};


export const fetchMyStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}my/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchMyStudentsAction(cb)))
    };
};


export const fetchAllLeftStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}all/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchAllStudentsAction(cb)))
    };
};

export const fetchSectionLeftStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}section/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSectionStudentsAction(cb)))
    };
};


export const admissionStatusUpdate = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}all/students/update/status`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const studentPasswordReset = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}all/students/reset/password`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const accessStatusUpdate = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}all/students/update/access`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const nameUpdate = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}all/students/update/name`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const updatePreHalfYearlyMarks = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}marks/half-yearly/preMarks/students`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const updatePreFinalMarks = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}marks/final/preMarks/students`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const updatePreQuarterMarks = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}marks/quarter/preMarks/students`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};

export const changeStudentSection = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.STUDENTS}change-section/student`,
            auth: 'bearer',
            body: data.studentInfo,
        },  async (cb) => {
            if (data.filterInfo.filters.sessionId) {
                dispatch(fetchAllStudents(data.filterInfo.filters))
            }
        })
    };
};


export const fetchMarksEntryStudents = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.STUDENTS}/marks/entry/students`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchMarksEntryStudentsAction(cb)))
    };
};