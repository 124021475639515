import actions from "./actions";

const {FETCH_PAYMENTS, FETCH_PAYMENT} = actions;
const initState = {
    payments: [],
    amount: []
};

const PaymentsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PAYMENTS:
            return {
                ...state,
                payments: data
            }
        case FETCH_PAYMENT:
            return {
                ...state,
                amount: data
            }
        default:
            return state;
    }
}

export default PaymentsReducer;
