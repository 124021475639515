const actions = {
    FETCH_PAYMENT_TYPES : 'FETCH_PAYMENT_TYPES',
    FETCH_PAYMENT_TYPE  : 'FETCH_PAYMENT_TYPE',
    RESET_PAYMENT_TYPE  : 'RESET_PAYMENT_TYPE',

    fetchPaymentTypesAction: (data) => {
        return {
            type: actions.FETCH_PAYMENT_TYPES,
            data: data
        };
    },
    resetPaymentTypeFormAction: () => {
        return {
            type: actions.RESET_PAYMENT_TYPE,
            data: null
        };
    },
    fetchPaymentTypeAction: (data) => {
        return {
            type: actions.FETCH_PAYMENT_TYPE,
            data: data
        };
    },
}

export default actions;
