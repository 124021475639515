const actions = {
    FETCH_MOCKS : 'FETCH_MOCKS',
    FETCH_MOCK  : 'FETCH_MOCK',
    RESET_MOCK  : 'RESET_MOCK',

    fetchMocksAction: (data) => {
        return {
            type: actions.FETCH_MOCKS,
            data: data
        };
    },
    resetMockFormAction: () => {
        return {
            type: actions.RESET_MOCK,
            data: null
        };
    },
    fetchMockAction: (data) => {
        return {
            type: actions.FETCH_MOCK,
            data: data
        };
    },
}

export default actions;
