const actions = {
    FETCH_PERFORMANCE : 'FETCH_PERFORMANCE',

    fetchPerformanceAction: (data) => {
        return {
            type: actions.FETCH_PERFORMANCE,
            data: data
        };
    },
}

export default actions;
