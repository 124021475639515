import actions from "./actions";

const {FETCH_PAYMENT_TYPE, FETCH_PAYMENT_TYPES, RESET_PAYMENT_TYPE} = actions;
const initState = {
    paymentTypes: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    },
    paymentType: {_id: null}
};

const PaymentTypeReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PAYMENT_TYPES:
            return {
                ...state,
                paymentTypes: data
            }
        case RESET_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: initState.paymentType,
            };
        case FETCH_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: data,
            };
        default:
            return state;
    }
}

export default PaymentTypeReducer;
