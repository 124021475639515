import actions from "./actions";

const { INVOICE_STUDENTS_USERS, GET_INVOICES,  GET_INVOICE} = actions;
const initState = {
    invoiceStudentsUsers: {},
    getInvoices: {
    page: 0,
        perPage: 35,
        data: [],
        total: 0
},
    getInvoice: {_id: null}
};

const InvoiceReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {

        case INVOICE_STUDENTS_USERS:
            return {
                ...state,
                invoiceStudentsUsers: data
            }
        case GET_INVOICES:
            return {
                ...state,
                getInvoices: data
            }
        case GET_INVOICE:
            return {
                ...state,
                getInvoice: data
            }
        default:
            return state;
    }
}

export default InvoiceReducer;
