import actions from "./actions";

const {FETCH_ALL_STUDENTS_RESULTS_ACCESS} = actions;

const initState = {
    access: [],
};

const StudentsResultAccessReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ALL_STUDENTS_RESULTS_ACCESS:
            return {
                ...state,
                access: data
            }
        default:
            return state;
    }
}

export default StudentsResultAccessReducer;