import actions from "./actions";

const {FETCH_TRANSACTIONS} = actions;
const initState = {
    transactions: {
        page: 0,
         perPage: 35,
        data: [],
        total: 0
    }
};

const TransactionsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_TRANSACTIONS:
            return {
                ...state,
                transactions: data
            }
        default:
            return state;
    }
}

export default TransactionsReducer;
