const actions = {
    FETCH_ALL_STUDENTS : 'FETCH_ALL_STUDENTS',
    FETCH_MARKS_ENTRY_STUDENTS : 'FETCH_MARKS_ENTRY_STUDENTS',
    FETCH_MY_STUDENTS : 'FETCH_MY_STUDENTS',
    FETCH_SECTION_STUDENTS : 'FETCH_SECTION_STUDENTS',

    fetchAllStudentsAction: (data) => {
        return {
            type: actions.FETCH_ALL_STUDENTS,
            data: data
        };
    },
    fetchSectionStudentsAction: (data) => {
        return {
            type: actions.FETCH_SECTION_STUDENTS,
            data: data
        };
    },
    fetchMyStudentsAction: (data) => {
        return {
            type: actions.FETCH_MY_STUDENTS,
            data: data
        };
    },
    fetchMarksEntryStudentsAction: (data) => {
        return {
            type: actions.FETCH_MARKS_ENTRY_STUDENTS,
            data: data
        };
    },
}

export default actions;
