import actions from "./actions";

const {FETCH_NOTICE, FETCH_NOTICES, RESET_NOTICE_FORM, RESET_NOTICE_LIST} = actions;
const initState = {
    notice: {},
    notices:{},
};

const NoticeReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_NOTICE:
            return {
                ...state,
                notice: data
            }
        case FETCH_NOTICES:
            return {
                ...state,
                notices: data
            }
        case RESET_NOTICE_FORM:
            return {
                ...state,
                notice: initState.notice
            }
        case RESET_NOTICE_LIST:
            return {
                ...state,
                notices: initState.notices
            }
        default:
            return state;
    }
}

export default NoticeReducer;
